import React, { useEffect, useRef, useState } from 'react';
import "./About.css";


function About() {

    const textContainerRef = useRef(null);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.8) {
                    setShowText(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const observer = new IntersectionObserver(handleIntersect, options);

        const sectionRef = document.getElementById('about-us');


        if (sectionRef) {
            observer.observe(sectionRef);
        }

        return () => {
            if (sectionRef) {
                observer.unobserve(sectionRef);
            }

        };

    }, []);


    const handleIntersect = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    };

    return (

        <section id="about" className="m-4">



            <div className="mx-auto text-center max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div className="mx-auto max-w-3xl">
                    <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                        About Us
                    </h2>


                </div>
                <section>
                    <div className="mx-auto max-w-screen-2xl px-4 py-16 sm:px-6 lg:px-8">
                        <div className="grid grid-cols-1 lg:h-screen lg:grid-cols-2">
                            <div className="relative z-10 lg:py-16">
                                <div className="relative h-64 sm:h-80 lg:h-full">
                                    <img
                                        alt="House"
                                        src="./about-1.jpg"
                                        className="absolute inset-0 h-full w-full object-cover"
                                    />
                                </div>
                            </div>

                            <div className="relative flex items-center bg-gradient-to-b from-rose-600 to-rose-800">
                                <span
                                    className="hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gradient-to-b from-rose-600 to-rose-800"
                                ></span>

                                <div className="p-8 sm:p-16 lg:p-24">
                                    <h2 className="text-xl text-gray-50 md:text-3xl font-bold school-bell-text">
                                        Redwood is dedicated to offering young minds a holistic educational journey that nurtures their social, emotional, and academic growth.
                                    </h2>

                                    <p className="mt-4 text-gray-200 text-xs md:text-base montserrat-text-500">
                                        We go beyond traditional academic subjects and focus on cultivating essential life skills such as critical thinking, problem-solving, collaboration, and creativity. We strive to create a well- rounded education that prepares our students not just for their future academic pursuits but also for success in an ever-evolving world.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>




        </section >

    );
}

export default About;