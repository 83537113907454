import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

function Daycare() {
    const textContainerRef = useRef(null);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.9) {
                    setShowText(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const controls = useAnimation();


    useEffect(() => {

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    controls.start('visible');
                }
            });
        };


        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const observer = new IntersectionObserver(handleIntersection, options);


        const circleCardElements = document.querySelectorAll('.circle-card' || '.daycare-circle-card');


        circleCardElements.forEach((element) => observer.observe(element));


        return () => observer.disconnect();
    }, [controls]);

    const fadeIn1 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } },
    };

    const fadeIn2 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1, ease: 'easeInOut' } },
    };

    const fadeIn3 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    const fadeIn4 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    const fadeIn5 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    const fadeIn6 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };


    return (
        <section className="montserrat-text-500">
            <section id="daycare" className={`program-bg m-0 text-container ${showText ? 'show' : ''
                }`}
                ref={textContainerRef}>

                <div
                    className="mx-auto max-w-screen-xl px-12 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8 lg:py-2 lg:mt-20"
                >
                    <div className="mx-auto max-w-3xl text-center m-12 daycare-circle-card">
                        <h2 className="text-xl md:text-3xl font-bold school-bell-text">Redwood Daycare Program</h2>
                        <p className="mt-4 text-gray-700 text-xs md:text-base">
                            Redwood offers a caring and comprehensive daycare program for infants and toddlers, as well as children. We deeply appreciate and empathize with passion, responsibilities, and financial commitments of working moms, and that's why we warmly welcome infants from as young as 3 months old up to toddlers aged 2 years. Our daycare provides a nurturing and safe environment where your little ones can thrive and experience optimal growth and development.
                        </p>
                    </div>
                </div>


                <div class="grid mx-auto max-w-screen-xl px-8 py-16 sm:px-6 sm:py-24 lg:px-8">
                    <div class="grid grid-cols-2 gap-32 justify-items-center school-bell-text">
                        <div
                            className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card daycare-circle-card toddler-session"

                        >
                            <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                                <a href="#">
                                    <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Infant &<p>Toddler Care</p></h3>
                                </a>
                                <div className="mt-4 flex flex-col items-center">
                                    <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                        Age Group
                                        <p>
                                            3 months - 2 years
                                        </p>
                                    </span>
                                </div>
                                <div className="mt-4 flex flex-col items-center">
                                    <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                        Timing
                                        <p>
                                            9:30 a.m - 5:30 p.m
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div
                            className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card daycare-circle-card playgroup"

                        >
                            <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                                <a href="#">
                                    <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Children Care</h3>
                                </a>
                                <div className="mt-4 flex flex-col items-center">
                                    <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                        Age Group
                                        <p>
                                            2 years - 12 years
                                        </p>
                                    </span>
                                </div>
                                <div className="mt-4 flex flex-col items-center">
                                    <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                        Timing
                                        <p>
                                            12:30 p.m - 6:00 p.m
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="peach-bg">
                <div className="montserrat-text-500">
                    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">

                        <div
                            className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8"
                        >
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                                <div
                                    className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
                                >
                                    <img
                                        alt="Party"
                                        src="./toddler.png"
                                        className="absolute inset-0 h-full w-full object-cover sm:mt-4 sm:block"
                                    />
                                </div>

                                <div className="px-12 lg:py-24 bg-gradient-to-b from-rose-600 to-rose-800">
                                    <h2 className="text-xl md:text-3xl text-white school-bell-text">We have a dedicated infant and junior toddler room, which has been carefully designed to provide ample ventilation with a delightful and fully equipped baby sitting set-up with all the necessary essentials, from cozy cribs to interactive toys, to stimulate and engage your little ones.</h2>




                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="brown-bg">

                <div
                    className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:px-8"
                >
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div
                            className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full lg:py-16"
                        >
                            <img
                                alt="Party"
                                src="./outdoor.svg"
                                className="absolute inset-0 h-full w-full object-contain"
                            />
                        </div>
                        <div className="px-12 lg:py-24 text-center">
                            <h2 className="text-xl md:text-3xl font-bold school-bell-text">Our facility is thoughtfully designed to ensure high ventilation, creating a fresh and comfortable atmosphere for children to thrive. </h2>
                            <p className="mt-4 text-gray-700 text-xs md:text-base">
                                We prioritize the well-being of our students by maintaining a caring and hygienic environment, implementing strict safety measures, and adhering to the highest standards of cleanliness. The safety and security of our children are of utmost importance to us, and we take every precaution necessary to provide parents with peace of mind.
                            </p>
                        </div>
                    </div>
                </div>

            </section>



        </section>
    );
}

export default Daycare;

