import React, { useEffect, useState, useRef } from 'react';

function NumberCounter({ value, duration }) {
    const [count, setCount] = useState(0);
    const countRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let observer;
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        if (countRef.current) {
            observer = new IntersectionObserver(handleIntersect, options);
            observer.observe(countRef.current);
        }

        return () => {
            if (observer && countRef.current) {
                observer.unobserve(countRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            let startValue = 0;
            const endValue = value;
            const incrementTime = (duration * 1000) / (endValue - startValue); // Calculate increment time based on duration
            let timer;

            if (value > 0) {
                timer = setInterval(() => {
                    setCount((prevCount) => {
                        const updatedCount = prevCount + 1;
                        return updatedCount >= endValue ? endValue : updatedCount;
                    });
                }, incrementTime);
            }

            return () => clearInterval(timer);
        }
    }, [value, duration, isVisible]);

    const handleIntersect = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    };

    return (
        <span ref={countRef}>{count}</span>
    );
}

export default NumberCounter;
