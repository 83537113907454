import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';
import AppRouter from "./components/AppRouter";
import Home from './components/Home';
import Testimonials from './components/Testimonials';
import About from './components/About';
import Programmes from './components/Programmes';
import Daycare from './components/Daycare';
import Facilities from './components/Facilities';
import Preschool from './components/Preschool';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Mission from './components/Mission';
import Team from './components/Team';
import Pedagogy from './components/Pedagogy';


function App() {
  return (
    <div className="nav">
      <Navbar />
      <Home />
      <About />
      <Mission />


      <Preschool />
      <Pedagogy />
      <Team />
      <Daycare />
      <Facilities />
      <Gallery />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
