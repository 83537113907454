import React, { useEffect, useState, useRef } from 'react';
import NumberCounter from './NumberCounter';

function Gallery() {
    const textContainerRef = useRef(null);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;



                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.9) {
                    setShowText(true);
                }
            }
        };



        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <section className="montserrat-text-500">
            <section id="why" className={`gallery text-container ${showText ? 'show' : ''
                }`}
                ref={textContainerRef}>
                <div className="mx-auto px-12 max-w-3xl text-center m-12">
                    <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                        Why Redwood?
                    </h2>

                    <p className="mt-4 text-gray-900 text-xs md:text-base">
                        Trust Redwood for a secure learning environment. Our core focus is providing a safe and nurturing space for our students. Find out what sets us apart from the rest.
                    </p>
                </div>

                <div className="mx-auto max-w-screen-xl px-12 py-4 sm:px-6 sm:py-4 lg:px-8">
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 lg:gap-16">


                        <article className="bg-gradient-to-b from-orange-100 to-orange-300 overflow-visible rounded-lg shadow transition hover:shadow-lg">

                            <div className="flex items-center justify-center pt-16"><img
                                alt="Office"
                                src="./secured.svg"
                                className="object-fill"
                            />
                            </div>
                            <div className="p-4 sm:p-6">


                                <a href="#">
                                    <h3 className="mt-0.5 text-sm md:text-base font-bold text-gray-900 flex items-center justify-center">
                                        Safe Environment
                                    </h3>
                                </a>

                                <p className="pt-4 mt-1 text-xs md:text-sm text-gray-900">
                                    We take every measure to ensure that our students feel protected, nurtured, and free to explore their surroundings with confidence. From carefully monitored play areas to state-of-the-art security systems, we prioritize the safety and well-being of every child under our care.
                                </p>
                            </div>
                        </article>

                        <article className="bg-gradient-to-b from-green-100 to-green-300 overflow-visible rounded-lg shadow transition hover:shadow-lg">

                            <div className="flex items-center justify-center pt-16"><img
                                alt="Office"
                                src="./staff.svg"
                                className="object-fill"
                            />
                            </div>
                            <div className="p-4 sm:p-6">


                                <a href="#">
                                    <h3 className="mt-0.5 text-sm md:text-base font-bold text-gray-900 flex items-center justify-center">
                                        Certified Staffs
                                    </h3>
                                </a>

                                <p className="pt-4 mt-1 text-xs md:text-sm text-gray-900">
                                    Our team consists of dedicated and trained professionals who are passionate about early childhood education. They are carefully selected for their expertise, experience, and commitment.

                                </p>
                            </div>
                        </article>

                        <article className="bg-gradient-to-b from-purple-100 to-purple-300 overflow-visible rounded-lg shadow transition hover:shadow-lg">

                            <div className="flex items-center justify-center pt-16"><img
                                alt="Office"
                                src="./safe.svg"
                                className="object-fill"
                            />
                            </div>
                            <div className="p-4 sm:p-6">


                                <a href="#">
                                    <h3 className="mt-0.5 text-sm md:text-base font-bold text-gray-900 flex items-center justify-center">
                                        Hygiene
                                    </h3>
                                </a>

                                <p className="pt-4 mt-1 text-xs md:text-sm text-gray-900">
                                    We maintain a clean and hygienic environment throughout our facility. Regular cleaning and disinfection protocols are followed to ensure the health and well-being of our students.

                                </p>
                            </div>
                        </article>

                        <article className="bg-gradient-to-b from-pink-100 to-pink-300 overflow-visible rounded-lg shadow transition hover:shadow-lg">

                            <div className="flex items-center justify-center pt-16"><img
                                alt="Office"
                                src="./value.svg"
                                className="object-fill"
                            />
                            </div>
                            <div className="p-4 sm:p-6">


                                <a href="#">
                                    <h3 className="mt-0.5 text-sm md:text-base font-bold text-gray-900 flex items-center justify-center">
                                        Value for Money
                                    </h3>
                                </a>

                                <p className="pt-4 mt-1 text-xs md:text-sm  text-gray-900">
                                    Delivering exceptional education and holistic development for your child is our priority, ensuring that your investment provides excellent returns.
                                </p>
                            </div>
                        </article>



                    </div>
                </div>
            </section>
            <section id="after-school-daycare" className="peach-bg py-16 mt-8">


                <div
                    className="mx-auto max-w-screen-xl px-12 py-8 sm:py-12 sm:px-6 lg:py-8 lg:px-8"
                >
                    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                        <div
                            className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
                        >
                            <div
                                className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
                            >
                                <img
                                    alt="Party"
                                    src="./6.svg"
                                    className="absolute inset-0 h-full w-full object-cover"
                                />
                            </div>
                        </div>
                        <div className="lg:py-24 text-center">
                            <h2 className="text-xl md:text-3xl font-bold school-bell-text">Let Redwood's after-school wonderland nurture their dreams and spark their brilliance!</h2>
                            <p className="mt-4 text-gray-700 text-xs md:text-base">
                                At Redwood, we offer the best of both worlds. Our tutoring facilities provide personalized support in various subjects, helping students strengthen their academic skills and reach their full potential. Additionally, our diverse extracurricular activities, including art, music, dance, and sports, offer students a platform to explore their interests, develop new skills, and foster creativity.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="top-choice" className="testimonials-bg text-xs md:text-base">

                <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">

                    <div className="mx-auto px-12 max-w-3xl text-center">
                        <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                            The Top Choice for Parents
                        </h2>

                        <p className="mt-4 text-gray-700 text-xs md:text-base montserrat-text-500">
                            Join us at Redwood, where education is not just about acquiring knowledge but about shaping young
                            minds and empowering them to become confident, compassionate, and lifelong learners.
                        </p>
                    </div>

                    <div className="mt-8 sm:mt-12">
                        <dl
                            className="grid grid-cols-1 gap-4 sm:grid-cols-3 sm:divide-x sm:divide-gray-900"
                        >
                            <div className="flex flex-col px-4 py-8 text-center">
                                <dt className="order-last text-lg font-medium text-gray-700 montserrat-text-500 text-xs md:text-base">
                                    Years of Experience
                                </dt>

                                <dd className="bg-gradient-to-b from-rose-600 to-rose-800 text-transparent bg-clip-text text-4xl font-extrabold md:text-5xl montserrat-text-500 montserrat-text-500">
                                    <NumberCounter value={8} duration={2} />+
                                </dd>
                            </div>

                            <div className="flex flex-col px-4 py-8 text-center">
                                <dt className="order-last text-lg font-medium text-gray-700 montserrat-text-500 text-xs md:text-base">
                                    Kids Nurtured
                                </dt>

                                <dd className="bg-gradient-to-b from-rose-600 to-rose-800 text-transparent bg-clip-text text-4xl font-extrabold md:text-5xl montserrat-text-500"><NumberCounter value={100} duration={3} />+</dd>
                            </div>

                            <div className="flex flex-col px-4 py-8 text-center">
                                <dt className="order-last text-lg font-medium text-gray-700 montserrat-text-500">
                                    Satisfied Parents
                                </dt>

                                <dd className="bg-gradient-to-b from-rose-600 to-rose-800 text-transparent bg-clip-text text-4xl font-extrabold md:text-5xl montserrat-text-500"><NumberCounter value={100} duration={3} />%</dd>
                            </div>

                        </dl>
                    </div>
                </div>

            </section>

        </section>
    );
}

export default Gallery;
