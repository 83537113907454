import React, { useRef, useState, useEffect } from 'react';
import "./Facilities.css";

function Facilities() {
    const textContainerRef = useRef(null);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;



                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.9) {
                    setShowText(true);
                }
            }
        };



        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (

        <section id="facilities" className={`peach-bg bg-white-900 text-black montserrat-text-500 text-container ${showText ? 'show' : ''
            }`}
            ref={textContainerRef}>

            <div className="mx-auto max-w-screen-xl px-12 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div className="mx-auto max-w-3xl text-center">
                    <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                        Our Facilities
                    </h2>

                    <p className="mt-4 text-xs md:text-base text-gray-900">
                        Redwood offers a range of top-notch facilities, ensuring a safe and enriching environment for children. Our services include convenient transportation within the HSR Layout area, live CCTV monitoring for parents' peace of mind, and nutritious meals carefully curated for children's well-being. With much more to offer, discover the wonder-filled world of Redwood.
                    </p>
                </div>


                <div className="mt-8 grid grid-cols-1 gap-200 md:mt-16 md:grid-cols-1 md:gap-20  lg:grid-cols-3">

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/cctv 1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Live Streaming</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                With our super cool live streaming CCTV systems, parents can keep an eye on their little ones from anywhere, on any device!

                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/meal 1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Healthy Meals</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">

                                Our menu is carefully curated to offer balanced and
                                healthy vegetarian options that cater to their dietary needs, ensuring they receive the essential
                                nutrients they need for optimal well-being.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/bus 1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Transportation</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">

                                To ensure ease of access, we offer transport facilities within the HSR Layout area. Our
                                reliable and safe transportation service provides a convenient solution.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/first-aid-box 1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">First Aid Kit</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">

                                Our facility is equipped with fire protection systems, and fire extinguishers. Additionally, we have a well-stocked first aid kit on-site to handle any minor injuries
                                or accidents that may occur.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/rc-car 1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Lead Free Toys</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                We understand the importance of providing a safe and healthy play environment.
                                That&#39;s why we carefully select toys that are free from harmful substances like lead, ensuring the
                                safety of our children during playtime.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/playground 1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Play Area</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                We believe in fostering creativity and imagination. Our
                                dedicated areas for artwork, storytelling, and reading provide a stimulating environment where
                                children can explore their talents.
                            </p>
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default Facilities;
