import React, { useEffect, useRef, useState } from 'react';

function Team() {
    const textContainerRef = useRef(null);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.8) {
                    setShowText(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (

        <section className={`peach-bg bg-white-900 text-black montserrat-text-500 text-container ${showText ? 'show' : ''
            }`}
            ref={textContainerRef}>

            <div className="mx-auto max-w-screen-xl px-12 py-16 sm:px-6 sm:py-24 lg:px-8">
                <div className="mx-auto max-w-3xl text-center">
                    <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                        How a day would be at Redwood
                    </h2>

                    <p className="mt-4 text-xs md:text-base text-gray-900">
                        We create a balanced routine that focuses on intellectual development, physical health, language skills, self-expression, and social interaction.
                    </p>
                </div>


                <div className="mt-8 grid grid-cols-1 gap-200 md:mt-16 md:grid-cols-1 md:gap-20  lg:grid-cols-3">

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-1.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Meet & Greet</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                A day at Redwood Preschools begins with a warm and welcoming casual get-together, exchanging cheerful morning greetings and setting a positive atmosphere for the day.

                            </p>
                        </div>
                    </div>

                    {/* <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-2.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Greet</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">

                                Educators and children exchange cheerful morning greetings, setting a positive atmosphere for the day.
                            </p>
                        </div>
                    </div> */}

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-3.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Montessori Sessions</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                It is then followed by Montessori sessions which emphasizes hands-on learning, independence and child-led exploration.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-4.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Physical Activities</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">

                                After montessori sessions, physical activities include yoga, other exercises and body balancing movements targeting gross motor skills.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-6.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Rhyme Time</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                A delightful "Rhyme Time" session is carried out to engage children's minds in a creative and enjoyable way.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-7.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Snacks Break</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                Following the engaging Rhyme Time session, children enjoy a well-deserved break for snacks.
                            </p>
                        </div>
                    </div>


                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-8.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Show & Tell Session</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                Again we start with a show and tell session, which focuses on self-expression and speaking abilities.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/book.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Board & Book Time</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                Kindergarten kids will be engaged in interactive board and book activities that aims to equip them with essential skills which will help them in seamless transition into the primary school environment.
                            </p>
                        </div>
                    </div>



                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/day-at-redwood-9.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Indoor Play</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                Later an indoor play session focused on creativity and problem solving.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start gap-12 m-2">
                        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
                            <img src="/slider.svg"></img>
                        </span>

                        <div>
                            <h2 className="text-sm md:text-lg font-bold">Outdoor Play</h2>

                            <p className="mt-1 text-xs md:text-sm text-white-300">
                                We end the day with an outdoor play session focuses on exploration and social interaction.
                            </p>
                        </div>
                    </div>
                </div>
            </div>



        </section >

    )
}
export default Team;