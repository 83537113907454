import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.css';
import 'tailwindcss/tailwind.css';

function Home() {
    return (

        <div>
            <section className="brown-bg width-100 sm:grid sm:grid-cols-1 md:grid-cols-2 sm:items-center pt-0 header">
                <div className="p-8 md:p-12 lg:px-16 lg:py-24">
                    <div className="mx-auto max-w-xl sm:text-left header-text mt-0">
                        <h2 className="text-xlf ont-bold text-gray-900 md:text-3xl heading-text">
                            Ready to give your child the best possible start in their journey of life?
                        </h2>
                        <p className="hidden text-gray-900 md:mt-4 md:block montserrat-text-500">

                            Book a tour today to visit our preschool and witness the magic of growth and thriving.
                        </p>
                        <div className="mt-4 md:mt-8">
                            <a
                                href="#contact"
                                className="bg-gradient-to-b from-rose-600 to-rose-800 hover:from-rose-500 hover:to-rose-800 inline-block rounded px-12 py-3 text-sm font-medium text-white transition focus:outline-none focus:ring montserrat-text-500"
                            >
                                Visit Us
                            </a>
                        </div>
                    </div>
                </div>
                {/* <Carousel autoPlay interval={2000} infiniteLoop showArrows showStatus showThumbs={false}> */}

                <div>
                    <img
                        className="h-[calc(100vh - 8rem)] w-full object-cover sm:h-[calc(100vh - 2rem)] sm:self-end md:h-[calc(100vh - 4rem)]"
                        src="./header-1.jpg"
                        alt="Image 2"
                    />
                </div>

                {/* </Carousel> */}
            </section>
        </div>
    );
}

export default Home;
