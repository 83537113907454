import React, { useEffect, useState } from 'react';
import './Navbar.css';
import Swiper from 'swiper';
import { Link } from 'react-scroll';

function Navbar() {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const swiper = new Swiper('.swiper', {
            effect: 'fade',
            loop: true,
            autoplay: {
                delay: 5000,
            },
            fadeEffect: {
                crossFade: true,
            },
            navigation: {
                prevEl: '.swiper-prev-button',
                nextEl: '.swiper-next-button',
            },
        });
    }, []);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 600 && isMobileMenuOpen) {
                setIsMobileMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isMobileMenuOpen]);

    return (
        <nav className="navbar">

            <section id="nav">

                <header className="fixed top-50 z-50 w-full brown-bg">
                    <div className="bg-gradient-to-r from-rose-600 to-rose-800 mx-auto flex max-w-full items-center justify-center">
                        <button
                            className="swiper-prev-button hidden hover:text-gray-50 sm:block sm:rounded sm:text-gray-50 sm:transition"
                            aria-label="Previous slide"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 rtl:rotate-180"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>

                        <div className="swiper">
                            <div className="swiper-wrapper montserrat-text-500">
                                <div className="swiper-slide">
                                    <p className="text-center text-xs md:text-sm font-medium text-gray-50">
                                        🎉 Admissions open for preschool session!
                                    </p>
                                </div>

                                <div className="swiper-slide">
                                    <p className="text-center text-xs md:text-sm font-medium text-gray-50">
                                        🎉  Admissions open for Daycare! Both infant and children daycare admissions are happening.
                                    </p>
                                </div>

                                <div className="swiper-slide">
                                    <p className="text-center text-xs md:text-sm font-medium text-gray-50">
                                        🎉  School admissions open for the kids of age group from 1.8 years to 6 years!
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button
                            className="swiper-next-button hidden hover:text-gray-50 sm:block sm:rounded sm:text-gray-50 sm:transition"
                            aria-label="Next slide"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 rtl:rotate-180"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </div>
                    <nav className="navbar hidden lg:block">
                        <div
                            className="mx-auto flex h-16 max-w-screen-xl items-center gap-8 px-4 sm:px-6 lg:px-8"
                        >

                            <a href="#nav" className="block text-teal-6000">
                                <span className="sr-only">Home</span>
                                <img className="h-28" src="./Redwood.svg" alt="Logo" />
                            </a>


                            <div className="flex flex-1 items-center justify-end md:justify-between">
                                <nav aria-label="Global" className="hidden md:block">
                                    <ul className="flex items-center gap-6 text-md school-bell-text">
                                        <li>
                                            <Link
                                                to="about"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                                className="text-gray-900 transition hover:text-gray-500/75"
                                            >

                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="programmes"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                                className="text-gray-900 transition hover:text-gray-500/75"
                                            >

                                                Preschool
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="daycare"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                                className="text-gray-900 transition hover:text-gray-500/75"
                                            >

                                                Daycare
                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="facilities"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                                className="text-gray-900 transition hover:text-gray-500/75"
                                            >
                                                Facilities

                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="why"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                                className="text-gray-900 transition hover:text-gray-500/75"
                                            >

                                                Why Redwood?

                                            </Link>
                                        </li>

                                        <li>
                                            <Link
                                                to="testimonials"
                                                spy={true}
                                                smooth={true}
                                                offset={-70}
                                                duration={500}
                                                className="text-gray-900 transition hover:text-gray-500/75"
                                            >
                                                Testimonials


                                            </Link>
                                        </li>
                                    </ul>
                                </nav>

                                <div className="flex items-center gap-3 lg:gap-6">
                                    <div className="sm:flex sm:gap-1">
                                        <a href="https://www.facebook.com/RedWoodHSR/" target="_blank" className="block text-teal-6000">

                                            <img className="h-5 lg:h-7" src="./fb-1.png" alt="Logo" />
                                        </a>


                                    </div>
                                    <div className="sm:flex sm:gap-1">
                                        <a href="https://wa.me/917899462200?text=Hello%20Redwood%20School,%20I'm%20interested%20in%20exploring%20the%20school/day%20care%20for%20my%20Kid(s).%20Please%20get%20back." target="_blank" className="block text-teal-6000">

                                            <img className="h-5 lg:h-7" src="./whatsapp.png" alt="Logo" />
                                        </a>


                                    </div>
                                    <div className="sm:flex sm:gap-4">
                                        <a href="#contact-us"
                                            className="bg-gradient-to-b from-rose-600 to-rose-800 hover:from-rose-500 hover:to-rose-800 block rounded-md px-2 py-2 lg:px-6 lg:py-3 text-xs font-medium text-white transition montserrat-text-500"
                                            href="#contact"
                                        >
                                            Contact
                                        </a>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </nav>
                </header>
            </section>

            <div className="flex brown-bg items-center justify-between max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <a href="#nav" className="lg:hidden block text-teal-6000 mb-0 pb-0">
                    <span className="sr-only">Home</span>
                    <img className="h-40" src="./Redwood.svg" alt="Redwood Preschool Daycare Logo" />
                </a>

                {/* Mobile Menu Button */}
                <button
                    className={`p-2 text-gray-900 hover:text-gray-500 md:hidden ${isMobileMenuOpen ? 'hidden' : 'block'
                        }`}
                    onClick={toggleMobileMenu}
                >
                    {/* Hamburger Menu Icon */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
                    </svg>
                </button>

                {/* Close Menu Button */}
                <button
                    className={`p-2 text-gray-900 hover:text-gray-500 md:hidden ${isMobileMenuOpen ? 'block' : 'hidden'
                        }`}
                    onClick={toggleMobileMenu}
                >
                    {/* Close Menu Icon */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>

            {/* Mobile Menu */}
            <div
                className={`${isMobileMenuOpen ? 'flex' : 'hidden'
                    } flex-col items-center justify-center gap-6 text-md school-bell-text mt-4 md:hidden`}
            >
                {/* Mobile Menu Items */}
                <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="text-gray-900 transition hover:text-gray-500/75"
                    onClick={toggleMobileMenu}
                >
                    About
                </Link>
                <Link
                    to="programmes"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="text-gray-900 transition hover:text-gray-500/75"
                    onClick={toggleMobileMenu}
                >
                    Preschool
                </Link>
                <Link
                    to="daycare"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="text-gray-900 transition hover:text-gray-500/75"
                    onClick={toggleMobileMenu}
                >
                    Daycare
                </Link>
                <Link
                    to="facilities"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="text-gray-900 transition hover:text-gray-500/75"
                    onClick={toggleMobileMenu}
                >
                    Facilities
                </Link>
                <Link
                    to="why"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="text-gray-900 transition hover:text-gray-500/75"
                    onClick={toggleMobileMenu}
                >
                    Why Redwood?
                </Link>
                <Link
                    to="testimonials"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    className="text-gray-900 transition hover:text-gray-500/75"
                    onClick={toggleMobileMenu}
                >
                    Testimonials
                </Link>
                <div className="grid grid-cols-2 items-center gap-3 lg:gap-6">
                    <div className="flex gap-4">
                        <div className="sm:flex sm:gap-1">
                            <a href="https://www.facebook.com/RedWoodHSR/" target="_blank" className="block text-teal-6000">

                                <img className="h-7" src="./fb-1.png" alt="Logo" />
                            </a>


                        </div>
                        <div className="sm:flex sm:gap-1">
                            <a href="https://wa.me/917899462200?text=Hello%20Redwood%20School,%20I'm%20interested%20in%20exploring%20the%20school/day%20care%20for%20my%20Kid(s).%20Please%20get%20back." target="_blank" className="block text-teal-6000">

                                <img className="h-7" src="./whatsapp.png" alt="Logo" />
                            </a>


                        </div>
                    </div>
                    <div className="sm:flex sm:gap-4">
                        <a href="#contact-us"
                            className="bg-gradient-to-b from-rose-600 to-rose-800 hover:from-rose-500 hover:to-rose-800 block rounded-md px-6 py-3 lg:px-6 lg:py-3 text-xs font-medium text-white transition montserrat-text-500"
                            href="#contact"
                        >
                            Contact
                        </a>


                    </div>


                </div>
            </div>
        </nav>
    );
}

export default Navbar;

