import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

function Preschool() {
    const textContainerRef = useRef(null);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.9) {
                    setShowText(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const controls = useAnimation();


    useEffect(() => {

        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    controls.start('visible');
                }
            });
        };


        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        };

        const observer = new IntersectionObserver(handleIntersection, options);


        const circleCardElements = document.querySelectorAll('.circle-card' || '.daycare-circle-card');


        circleCardElements.forEach((element) => observer.observe(element));


        return () => observer.disconnect();
    }, [controls]);

    const fadeIn1 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } },
    };

    const fadeIn2 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1, ease: 'easeInOut' } },
    };

    const fadeIn3 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    const fadeIn4 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    const fadeIn5 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    const fadeIn6 = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.5, ease: 'easeInOut' } },
    };

    return (

        <section id="preschool" className={`montserrat-text-500 program-bg m-0 text-container ${showText ? 'show' : ''
            }`}
            ref={textContainerRef}>
            <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8 lg:py-2 lg:mt-20">

                <section id="programmes" className="programmes mt-0">

                    <div className="mx-auto px-12 m-0 p-0 max-w-3xl text-center m-0">
                        <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                            Redwood Preschool Program
                        </h2>

                        <p className="mt-4 text-gray-900 text-xs md:text-base">
                            At Redwood, we pride ourselves on providing an innovative,
                            integrated, and time-tested curriculum that combines the best of
                            various teaching methodologies.
                        </p>
                    </div>
                </section>
            </div>
            <div className="grid grid-cols-3 gap-8 md:grid-cols-3 mx-auto max-w-screen-xl px-8 py-16 sm:px-6 sm:py-24 lg:px-8 school-bell-text">




                <motion.article
                    className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card toddler-session"
                    variants={fadeIn1}
                    initial="hidden"
                    animate={controls}
                >
                    <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                        <a href="#">
                            <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Toddler Session</h3>
                        </a>

                        <div className="mt-4 flex flex-col items-center">
                            <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                Age Group
                                <p>
                                    1.4 years - 2.2 years
                                </p>
                            </span>

                        </div>

                        <div className="mt-4 flex flex-col items-center">
                            <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                Timing
                                <p>
                                    9:30 a.m - 12:00 p.m
                                </p>
                            </span>

                        </div>
                    </div>
                </motion.article>



                <motion.article
                    className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card playgroup"
                    variants={fadeIn2}
                    initial="hidden"
                    animate={controls}
                >
                    <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                        <a href="#">
                            <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Playgroup</h3>
                        </a>

                        <div className="mt-4 flex flex-col items-center">
                            <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                Age Group
                                <p>
                                    1.8 years - 2.8 years
                                </p>
                            </span>

                        </div>

                        <div className="mt-4 flex flex-col items-center">
                            <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                Timing
                                <p>
                                    9:00 a.m - 12:30 p.m
                                </p>
                            </span>

                        </div>
                    </div>
                </motion.article>


                <motion.article
                    className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card nursery"
                    variants={fadeIn3}
                    initial="hidden"
                    animate={controls}
                >
                    <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                        <a href="#">
                            <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Nursery</h3>
                        </a>

                        <div className="mt-4 flex flex-col items-center">
                            <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                Age Group
                                <p>
                                    2.9 years - 3.8 years
                                </p>
                            </span>

                        </div>

                        <div className="mt-4 flex flex-col items-center">
                            <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                Timing
                                <p>
                                    9:00 a.m - 12:30 p.m
                                </p>
                            </span>

                        </div>
                    </div>
                </motion.article>

                <div className="relative gap-8 col-span-1 md:col-span-3 md:flex items-center md:justify-center md:mt-0">
                    <motion.article
                        className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card junior-kg mt-16"
                        variants={fadeIn4}
                        initial="hidden"
                        animate={controls}
                    >
                        <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                            <a href="#">
                                <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Junior KG</h3>
                            </a>

                            <div className="mt-4 flex flex-col items-center">
                                <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                    Age Group
                                    <p>
                                        3.8 years - 4.8 years
                                    </p>
                                </span>

                            </div>

                            <div className="mt-4 flex flex-col items-center">
                                <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                    Timing
                                    <p>
                                        9:00 a.m - 1:00 p.m
                                    </p>
                                </span>

                            </div>
                        </div>
                    </motion.article>


                    <motion.article
                        className="rounded-full overflow-hidden shadow transition hover:shadow-lg circle-card senior-kg mt-16"
                        variants={fadeIn5}
                        initial="hidden"
                        animate={controls}
                    >
                        <div className="p-4 sm:p-6 flex flex-col justify-center items-center h-full">
                            <a href="#">
                                <h3 className="mt-0.5 text-sm md:text-xl text-gray-900">Senior KG</h3>
                            </a>

                            <div className="mt-4 flex flex-col items-center">
                                <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                    Age Group
                                    <p>
                                        4.8 years - 5.8 years
                                    </p>
                                </span>
                            </div>

                            <div className="mt-4 flex flex-col items-center">
                                <span className="whitespace-nowrap rounded-full px-2 py-3 text-md text-gray-900">
                                    Timing
                                    <p>
                                        9:00 a.m - 1:00 p.m
                                    </p>
                                </span>

                            </div>
                        </div>
                    </motion.article>
                </div>
            </div>


        </section >
    );
}

export default Preschool;