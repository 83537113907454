import React from 'react';
import "./Contact.css";

function Contact() {
    return (
        <section id="contact" className="contact montserrat-text-500">
            <div class="container my-24 mx-auto md:px-6">


                <section class="mb-32">
                    <div
                        class="block rounded-lg brown-bg-b">
                        <div class="flex flex-wrap items-center">
                            <div class="block w-full shrink-0 grow-0 basis-auto lg:flex lg:w-6/12 xl:w-4/12 text-xs md:text-base">
                                <div class="h-[500px] w-full">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.025942170613!2d77.64428957985022!3d12.906053383854315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae149ec0539c17%3A0x1fa17bf03649b66d!2s1311%2C%2024th%20Main%20Rd%2C%20Garden%20Layout%2C%20Sector%202%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102%2C%20India!5e0!3m2!1sen!2sus!4v1717454905793!5m2!1sen!2sus"
                                        class="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" frameborder="0"
                                        allowfullscreen></iframe>
                                </div>
                            </div>
                            <div class="w-full shrink-0 grow-0 basis-auto lg:w-6/12 xl:w-8/12">
                                <div class="flex flex-wrap px-3 pt-12 pb-12 md:pb-0 lg:pt-0">
                                    <div className="mx-auto max-w-3xl text-center text-xs md:text-base">
                                        <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl school-bell-text">
                                            Contact Us
                                        </h2>

                                        <p className="mt-4 text-gray-700 sm:text-md">
                                            Contact Redwood Preschool & Daycare today! Take comfort in opting for Redwood as the leading choice for your child's foundational years. Give your child the best start in their educational journey. Contact Redwood Preschool & Daycare now for enrollment information and a tour of our facility.
                                        </p>
                                    </div>
                                    <div class="mb-0 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12 text-xs md:text-base">

                                        <div class="flex items-start">
                                            <div class="shrink-0">
                                                <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">

                                                    <span className="shrink-0 rounded-xs p-4">
                                                        <img src="/phone 1 (1).svg"></img>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="ml-6 grow p-8">
                                                <p class="mb-2 font-bold text-gray-900">
                                                    Phone Number
                                                </p>
                                                <p class="text-gray-700">
                                                    +91 7899462200
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p>
                                        <div class="mb-0 w-full shrink-0 grow-0 basis-auto px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12 text-xs md:text-base">
                                            <div class="flex items-start">
                                                <div class="shrink-0">
                                                    <div class="inline-block rounded-md bg-primary-100 p-4 text-primary">

                                                        <span className="shrink-0 rounded-xs p-4">
                                                            <img src="/email 1 (1).svg"></img>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="ml-6 grow p-8">
                                                    <p class="mb-2 font-bold text-gray-900">
                                                        Email
                                                    </p>
                                                    <p class="text-gray-700">
                                                        admin@redwoodpreschools.in
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>



                    </div>


                </section>

            </div>
        </section >
    );
}

export default Contact;
