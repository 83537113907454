import React, { useEffect, useRef, useState } from 'react';

function Pedagogy() {
    const textContainerRef = useRef(null);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;



                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.9) {
                    setShowText(true);
                }
            }
        };



        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <section className="montserrat-text-500">
            <section className={`text-container ${showText ? 'show' : ''
                }`}
                ref={textContainerRef}>
                <div>
                    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8 p-8 lg:p-24">
                        <div className="mx-auto max-w-3xl text-center">

                            <h2 className="text-lg md:text-3xl font-bold text-gray-900 school-bell-text">Our Pedagogy</h2>

                            <p className="mt-4 text-gray-700 text-xs md:text-base">
                                Our pedagogy is multi-dimensional, blending the Montessori method,
                                Waldorf techniques, and traditional teaching practices to create a
                                comprehensive learning experience for our students.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <div className="bg-gradient-to-b from-rose-600 to-rose-800 hover:from-rose-500 hover:to-rose-800 p-8 md:p-12 lg:px-16 lg:py-24">
                        <div className="mx-auto max-w-xl text-center">
                            <h2 className="text-xl md:text-3xl px-12 font-bold text-white school-bell-text">
                                The Montessori method, developed by Dr. Maria Montessori, forms an essential part of our curriculum.
                            </h2>

                            <p className="text-white/90 sm:mt-4 sm:block text-xs md:text-base">
                                It is a child-centric approach that encourages independent learning through child-led activities. Our teachers facilitate student learning, fostering a positive attitude towards school. Our stimulating Montessori environments promote creative, independent learning, developing critical thinking and collaboration for future success. By embracing the Montessori approach from an early age, our children develop critical thinking, collaboration and creativity and thereby they would be able to attain the cognitive, sensorial, gross and fine motor development.
                            </p>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4 md:grid-cols-1 lg:grid-cols-2">
                        <img
                            alt="Student"
                            src="./montessori-toy-1.jpg"
                            className="h-40 w-full object-cover sm:h-56 md:h-full"
                        />

                        <img
                            alt="Student"
                            src="./montessori-geo.png"
                            className="h-40 w-full object-cover sm:h-56 md:h-full"
                        />
                    </div>
                </div>
            </section>


            <section className="peach-bg">
                <div className="montserrat-text-500">
                    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">

                        <div
                            className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8"
                        >
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                                <div
                                    className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
                                >
                                    <img
                                        alt="Party"
                                        src="./5.png"
                                        className="absolute inset-0 h-full w-full object-cover sm:mt-4 sm:block"
                                    />
                                </div>

                                <div className="px-12 lg:py-2 text-center">
                                    <h2 className="text-xl md:text-3xl font-bold school-bell-text">We also incorporate the Waldorf technique into our curriculum, which places a strong emphasis on nurturing each child's authentic self.</h2>

                                    <p className="mt-4 text-gray-700 text-xs md:text-base">
                                        This child-centric approach focuses on individualization, holistic development integrating art, nature, and active learning. Our unhurried methodology supports cognitive, emotional, spiritual, and physical well-being. Art, service learning, multi-sensory teaching are included in our curriculum. Outdoor education fosters nature connection and real-life experiences.
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="brown-bg text-center">
                <div className="montserrat-text-500">
                    <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8 lg:py-2">

                        <div
                            className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8"
                        >
                            <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                                <div
                                    className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full"
                                >
                                    <img
                                        alt="Party"
                                        src="./traditional.svg"
                                        className="absolute inset-0 h-full w-full object-cover sm:mt-4 sm:block"
                                    />
                                </div>

                                <div className="px-12 lg:py-24">
                                    <h2 className="text-xl md:text-3xl font-bold school-bell-text">In addition to these progressive methodologies, we recognize the value of traditional teaching
                                        practices.</h2>

                                    <p className="mt-4 text-gray-700 text-xs md:text-base">
                                        In the kindergarten setting, it's incredibly beneficial for children to engage in board and book activities to cultivate the important qualities of patience while strengthening their observation and listening skills. By practicing patience, they develop the ability to wait their turn and focus on tasks. In addition honing their observation and listening skills help them to become more attuned to their surroundings and to the instructions and information provided by their teachers. Overall, this intentional practice during their formative years positively impacts their readiness for the next stage of their educational journey.
                                    </p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </section>
    )
}
export default Pedagogy;