import React, { useEffect, useRef, useState } from 'react';
import './Mission.css';

function Mission() {
    const textContainerRef = useRef(null);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (textContainerRef.current) {
                const topOffset = textContainerRef.current.getBoundingClientRect().top;
                const windowHeight = window.innerHeight;

                if (topOffset < windowHeight * 0.8) {
                    setShowText(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="peach-bg montserrat-text-500">
            <div
                className={`mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8 text-container ${showText ? 'show' : ''
                    }`}
                ref={textContainerRef}
            >
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
                    <div className="mx-auto max-w-3xl text-center peach-bg p-8 lg:p-24">
                        <h2 className="text-xl md:text-3xl font-bold text-gray-900 school-bell-text">
                            Our Mission
                        </h2>

                        <p className="mt-4 text-gray-700 text-xs md:text-base">
                            At Redwood, our mission is to provide a nurturing environment where children explore diverse learning models. We focus on personalized attention, innovative teaching, and essential life skills. Our goal is to foster a lifelong love for learning and prepare students for success in an ever-changing world. With a dedicated team of passionate educators, we offer a safe and inclusive space for children to express themselves, learn from experiences, and ignite their curiosity and imagination.
                        </p>
                    </div>
                    <div className="px-12 lg:py-24 p-8 lg:p-24">
                        <h2 className="text-xl md:text-3xl font-bold school-bell-text">Our Team</h2>

                        <p className="mt-4 text-gray-700 text-xs md:text-base">
                            Redwood was founded by Dr. Poornima, a dedicated individual with expertise in dentistry, child psychology, and early childhood education. Our team consists of passionate Montessori-trained educators and supportive staff committed to fostering a vibrant learning community. We prioritize building strong relationships with children and families through open communication and collaboration. Join us on a transformative journey of growth and discovery for your child's future.
                        </p>


                    </div>
                    {/* <div
                        className="hidden sm:mt-4 sm:block"
                    >
                        <img
                            alt="Party"
                            src="./test-3.svg"
                            className="h-40 w-full object-cover sm:h-56 md:h-full rounded-full"
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Mission;
