import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Navbar from './Navbar';
import Home from './Home';
import About from './About';
import Programmes from './Programmes';
import Facilities from './Facilities';
import Gallery from './Gallery';
import Contact from './Contact';
import Footer from './Footer';
import Testimonials from './Testimonials';
import Daycare from './Daycare';

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navbar />} />
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/Programmes" element={<Programmes />} />
                <Route path="/Daycare" element={<Daycare />} />
                <Route path="/Facilities" element={<Facilities />} />
                <Route path="/Gallery" element={<Gallery />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Testimonials" element={<Testimonials />} />
                <Route path="/Footer" element={<Footer />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRouter