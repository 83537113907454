import React from 'react';
function Footer() {
    return (
        <section id="footer" className="footer montserrat-text-500 text-xs md:text-base">
            <footer className="dark-brown-bg">
                <div
                    className="relative mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8 lg:pt-24"
                >
                    <div className="absolute end-4 top-4 sm:end-6 sm:top-6 lg:end-8 lg:top-8">
                        <a
                            className="inline-block rounded-full bg-gradient-to-b from-rose-600 to-rose-800 hover:from-rose-500 hover:to-rose-800 p-2 text-white shadow transition hover:bg-teal-500 sm:p-3 lg:p-4"
                            href="#nav"
                        >
                            <span className="sr-only">Back to top</span>

                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </a>
                    </div>

                    <div className="lg:flex lg:items-end lg:justify-between">
                        <div className="">
                            <div className="flex justify-center text-rose-600 lg:justify-start">

                                <a href="#home" className="block text-rose-6000">
                                    <span className="sr-only">Home</span>
                                    <img className="h-40" src="./Redwood.svg" alt="Logo" />
                                </a>
                            </div>

                            <p
                                className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-900 lg:text-left"
                            >
                                Embark on a wondrous journey at Redwood, where safety, well-being, and growth reign supreme. Our nurturing haven embraces each child, fostering curiosity and creativity.
                            </p>
                        </div>

                        <ul
                            className="mt-12 flex flex-wrap justify-center gap-6 md:gap-8 lg:mt-0 lg:justify-end lg:gap-12"
                        >
                            <li>
                                <a className="text-gray-900 transition hover:text-gray-700/75" href="#about">
                                    About
                                </a>
                            </li>

                            <li>
                                <a className="text-gray-900 transition hover:text-gray-700/75" href="#programmes">
                                    Preschool
                                </a>
                            </li>

                            <li>
                                <a className="text-gray-900 transition hover:text-gray-700/75" href="#daycare">
                                    Daycare
                                </a>
                            </li>
                        </ul>
                    </div>

                    <p className="mt-12 text-center text-sm text-gray-900 lg:text-right text-xs md:text-base">
                        © 2023 Redwood Montessori Preschool & Daycare. All rights reserved.
                    </p>
                </div>
            </footer>

        </section>
    );
}

export default Footer;
